import Cookies from 'js-cookie'
import React, { useContext, useEffect, useState } from 'react'
import createCart from '../../api/createCart'
import getCart from '../../api/getCart'
import addCartLines from '../../api/addCartLines'
import removeCartLines from '../../api/removeCartLines'
import updateCartLines from '../../api/updateCartLines'

const CartContext = React.createContext({})

export const CartProvider = ({ children }: React.PropsWithChildren) => {
  const [cartLoaded, setCartLoaded] = useState(false)
  const [activeCart, setActiveCart] = useState(null)
  const [lineItems, setLineItems ] = useState([])
  const [estimatedCost, setEstimatedCost] = useState(null)
  const [totalQuantity, setTotalQuantity] = useState(0)
  const [recentlyAddedVariant, setRecentlyAddedVariant] = useState(null)
  const [showCart, setShowCart] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  
  useEffect(() => {
    const storedCartId = Cookies.get("EssentiaCartId")
    if (storedCartId) {
      getCart({
        cartId: storedCartId
      }).then((result) => {
        setActiveCart(result)
      })
    }

    setCartLoaded(true)
  }, [])

  useEffect(() => {
    let totalQty = 0
    if (activeCart?.lines?.edges) {
      activeCart.lines.edges.forEach(({ node }) => totalQty += node.quantity)
      setTotalQuantity(totalQty)
    }
    
    setLineItems(activeCart?.lines?.edges.map(({ node }) => node) ?? [])
    setEstimatedCost(activeCart?.estimatedCost ?? null)
  }, [activeCart])

  const addToCart = ({
    product,
    variant,
    quantity
  }, autoCheckout = false) => {
    if (autoCheckout || activeCart === null) {
      createCart({
        lines: [{
          merchandiseId: variant.id,
          quantity
        }]
      }).then((result) => {
        Cookies.set("EssentiaCartId", result.cart.id, { expires: 7 })
        if (autoCheckout) {
          proceedToCheckout(result.cart)
        } else {
          setActiveCart(result.cart)
          setRecentlyAddedVariant(variant)
          // setShowConfirmation(true)
          setShowCart(true)
        }
      })
    } else {
      addCartLines({
        cartId: activeCart.id,
        lines: [{
          merchandiseId: variant.id,
          quantity
        }]
      }).then((result) => {
        Cookies.set("EssentiaCartId", result.cart.id, { expires: 7 })
        setActiveCart(result.cart)
        setRecentlyAddedVariant(variant)
        // setShowConfirmation(true)
        setShowCart(true)
      })
    }
  }

  const updateCartItem = ({
    item,
    quantity
  }) => {
    if (activeCart !== null) {
      updateCartLines({
        cartId: activeCart.id,
        lines: [{
          id: item.id,
          merchandiseId: item.merchandise.id,
          quantity
        }]
      }).then((result) => {
        Cookies.set("EssentiaCartId", result.cart.id, { expires: 7 })
        setActiveCart(result.cart)
      })
    }
  }

  const removeLineItem = (lineItem) => {
    removeCartLines({
      cartId: activeCart.id,
      lineIds: [lineItem.id]
    }).then((result) => {
      Cookies.set("EssentiaCartId", result.cart.id, { expires: 7 })
      setActiveCart(result.cart)
    })
  }

  const proceedToCheckout = (autoCart = null) => {
    if (autoCart?.checkoutUrl || activeCart?.checkoutUrl) {
      window.location.assign(autoCart?.checkoutUrl || activeCart.checkoutUrl)
    }
  }

  const openCart = () => { setShowCart(true) }
  const closeCart = () => { setShowCart(false) }
  const closeConfirmation = () => { setShowConfirmation(false) }
  const clearRecentlyAddedVariant = () => { setRecentlyAddedVariant(null) }

  return (
    <CartContext.Provider value={{
      activeCart,
      cartLoaded,
      lineItems,
      estimatedCost,
      proceedToCheckout,
      removeLineItem,
      addToCart,
      updateCartItem,
      showCart,
      openCart,
      closeCart,
      showConfirmation,
      closeConfirmation,
      recentlyAddedVariant,
      clearRecentlyAddedVariant,
      totalQuantity
    }}>
      { children }
    </CartContext.Provider>
  )
}

export const useCart = () => useContext(CartContext)
