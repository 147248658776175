import React from 'react'
import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Monkeg';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(/fonts/MonkegRegular.woff2) format('woff2');
      }
      @font-face {
        font-family: 'Uncut Sans';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(/fonts/UncutSans-Regular.woff2) format('woff2');
      }
      `}
  />
)

export default Fonts