import React from 'react'
import Fonts from '../gatsby-plugin-chakra-ui/Fonts'
import { CartProvider } from './Store/CartProvider'

interface RootWrapperProps {
  element: any
}

export const RootWrapper = ({ element }: RootWrapperProps) => (
  <CartProvider>
    <Fonts />
    {element}
  </CartProvider>
)