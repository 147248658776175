import axios from 'axios'

export const shopifyRequest = (query: string, variables: Record<string, any>, silent = true) => (
  axios({
    url: `https://${process.env.GATSBY_SHOPIFY_SITE_NAME}.myshopify.com/api/2024-04/graphql.json`,
    method: 'post',
    headers: {
      'X-Shopify-Storefront-Access-Token': process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
      'Content-Type': 'application/json'
    },
    data: { query, variables }
  }).then((result) => {
    return result.data.data
  }).catch((error) => {
    // handle network errors
    if (!silent) {
      console.error(error)
    }
  })
)
