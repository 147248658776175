import { shopifyRequest } from '.'

interface RemoveCartLinesVariables {
  cartId: string,
  lineIds: string[]
}

const removeCartLines = async (variables: RemoveCartLinesVariables, silent = true) => {
  const result = await shopifyRequest(`
    mutation removeCartLines($cartId: ID!, $lineIds: [ID!]!) {
      cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
        cart {
          id
          checkoutUrl
          lines(first: 10) {
            edges {
              node {
                id
                quantity
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    product {
                      id
                      title
                    }
                    image {
                      id
                      src
                    }
                    priceV2 {
                      amount
                      currencyCode
                    }
                  }
                }
                estimatedCost {
                  subtotalAmount {
                    amount
                    currencyCode
                  }
                  totalAmount {
                    amount
                    currencyCode
                  }
                }
                attributes {
                  key
                  value
                }
              }
            }
          }
          estimatedCost {
            totalAmount {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalTaxAmount {
              amount
              currencyCode
            }
            totalDutyAmount {
              amount
              currencyCode
            }
          }
        }
        userErrors {
          field
          message
        }
      }
    }
  `, variables, silent)

  return result.cartLinesRemove
}

export default removeCartLines