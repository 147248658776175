import { shopifyRequest } from '.'

interface GetCartVariables {
  cartId: string
}

const getCart = async (variables: GetCartVariables, silent = true) => {
  const result = await shopifyRequest(`
    query GetCart($cartId: ID!) {
      cart(id: $cartId) {
        id
        checkoutUrl
        lines(first: 25) {
          edges {
            node {
              id
              quantity
              merchandise {
                ... on ProductVariant {
                  id
                  title
                  quantityAvailable
                  product {
                    id
                    title
                  }
                  image {
                    id
                    src
                  }
                  priceV2 {
                    amount
                    currencyCode
                  }
                }
              }
              estimatedCost {
                subtotalAmount {
                  amount
                  currencyCode
                }
                totalAmount {
                  amount
                  currencyCode
                }
              }
              attributes {
                key
                value
              }
            }
          }
        }
        attributes {
          key
          value
        }
        estimatedCost {
          subtotalAmount {
            amount
            currencyCode
          }
        }
      }
    }
  `, variables, silent)

  return result.cart
}

export default getCart
