exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-me-tsx": () => import("./../../../src/pages/about-me.tsx" /* webpackChunkName: "component---src-pages-about-me-tsx" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-weddings-tsx": () => import("./../../../src/pages/weddings.tsx" /* webpackChunkName: "component---src-pages-weddings-tsx" */),
  "component---src-templates-album-template-tsx": () => import("./../../../src/templates/AlbumTemplate.tsx" /* webpackChunkName: "component---src-templates-album-template-tsx" */),
  "component---src-templates-faq-template-tsx": () => import("./../../../src/templates/FAQTemplate.tsx" /* webpackChunkName: "component---src-templates-faq-template-tsx" */),
  "component---src-templates-product-template-tsx": () => import("./../../../src/templates/ProductTemplate.tsx" /* webpackChunkName: "component---src-templates-product-template-tsx" */),
  "component---src-templates-vendor-template-tsx": () => import("./../../../src/templates/VendorTemplate.tsx" /* webpackChunkName: "component---src-templates-vendor-template-tsx" */)
}

