/* eslint-disable no-unused-expressions */
// import('typeface-quicksand')
// import('typeface-bebas-neue')

import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'

import firebase from 'gatsby-plugin-firebase'
import { RootWrapper } from './src/components/RootWrapper'

// if (process.env.NODE_ENV === 'development') {
//   firebase.firestore().useEmulator('localhost', 5556)
//   firebase.functions().useEmulator('localhost', 5555)
// }

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import('intersection-observer')
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}

firebase.auth().signInAnonymously()
  .then(() => {})
  .catch(() => {
    // const errorCode = error.code;
    // const errorMessage = error.message;
  })

  export const wrapRootElement = RootWrapper

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  window.history.scrollRestoration = 'manual'
  const currentPosition = getSavedScrollPosition(location)

  if (currentPosition) {
    window.scrollTo(...currentPosition)
  } else {
    window.scrollTo(0, 0)
  }

  return false
}
