import { shopifyRequest } from '.'

interface CreateCartVariables {
  lines?: any[]
}

const createCart = async (variables: CreateCartVariables = {}, silent = true) => {
  const result = await shopifyRequest(`
    mutation cartCreate($lines: [CartLineInput!]!) {
      cartCreate(input: {
        lines: $lines
      }) {
        cart {
          id
          checkoutUrl
          lines(first: 10) {
            edges {
              node {
                id
                quantity
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    quantityAvailable
                    product {
                      id
                      title
                    }
                    image {
                      id
                      src
                    }
                    priceV2 {
                      amount
                      currencyCode
                    }
                  }
                }
                estimatedCost {
                  subtotalAmount {
                    amount
                    currencyCode
                  }
                  totalAmount {
                    amount
                    currencyCode
                  }
                }
                attributes {
                  key
                  value
                }
              }
            }
          }
        }
        userErrors {
          field
          message
        }
      }
    }
  `, variables, silent)

  return result.cartCreate
}

export default createCart